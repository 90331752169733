import React, { useState } from 'react';
import { Toaster } from 'react-hot-toast';
import axios from 'axios';
// eslint-disable-next-line
import toast from 'react-hot-toast';
import discord from '../img/discord.svg';
import twitter from '../img/twitter.svg';
import youtube from '../img/youtube.png';
import contact_bg_01 from '../img/contact/01.png';
import contact_bg_02 from '../img/contact/02.png';
import contact_bg_03 from '../img/contact/03.png';
import contact_bg_04 from '../img/contact/04.png';
import contact_bg_05 from '../img/contact/05.png';
import contact_bg_06 from '../img/contact/06.png';
import serverConfig from '../config/backend-config.json';
import Header from './Header';
import FooterCopyright from './common/FooterCopyright';

const Contact = () => {
    document.body.classList.add('contact-page');
    const [name, setName] = useState(null);
    const [emailAddress, setEmailAddress] = useState(null);
    const [message, setMessage] = useState(null);
    const [errorShow, setErrorShow] = useState(false);
    // eslint-disable-next-line
    const [successShow, setShowShow] = useState(false);

    const handleOnSubmit = async () => {
        if (name === null || emailAddress === null || message === null) {
            setErrorShow(true);
        } else {
            try {
                const response = await axios.post(`${serverConfig.api}`, {
                    name: name,
                    email_address: emailAddress,
                    msg: message
                });
                console.log(response.status);

                setShowShow(true);
            } catch (error) {
                console.log(error);
            }
        }
    };

    return (
        <div>
            <Toaster
                position="top-center"
                reverseOrder={false}
                gutter={8}
                containerClassName=""
                containerStyle={{}}
                toastOptions={{
                    // Define default options
                    className: '',
                    duration: 5000,
                    style: {
                        background: '#363636',
                        color: '#fff',
                        maxWidth: 600,
                        marginTop: 26
                    },
                    // Default options for specific types
                    success: {
                        duration: 3000,
                        theme: {
                            primary: 'green',
                            secondary: 'black'
                        }
                    }
                }}
            />

            <Header />

            <section id="contact" className="contact-container">
                <div className="container">
                    <div className="bg">
                        <div className="contact_bg contact_bg_01">
                            <img
                                src={contact_bg_01}
                                className="contact_bg_img"
                                alt="logo"
                            />
                        </div>
                        <div className="contact_bg contact_bg_02">
                            <img
                                src={contact_bg_02}
                                className="contact_bg_img"
                                alt="logo"
                            />
                        </div>
                        <div className="contact_bg contact_bg_03">
                            <img
                                src={contact_bg_03}
                                className="contact_bg_img"
                                alt="logo"
                            />
                        </div>
                        <div className="contact_bg contact_bg_04">
                            <img
                                src={contact_bg_04}
                                className="contact_bg_img"
                                alt="logo"
                            />
                        </div>
                        <div className="contact_bg contact_bg_05">
                            <img
                                src={contact_bg_05}
                                className="contact_bg_img"
                                alt="logo"
                            />
                        </div>
                        <div className="contact_bg contact_bg_06">
                            <img
                                src={contact_bg_06}
                                className="contact_bg_img"
                                alt="logo"
                            />
                        </div>
                    </div>
                    <div className="inner">
                        <h1 className="title">Contacts</h1>
                        <p className="para">
                            If you&apos;re interested in collaborating with
                            Galaxy Peeps, please fill out the form below!
                            Thanks!
                        </p>
                        {successShow ? (
                            <div className="success">
                                <label>
                                    Thank you! Your submission has been
                                    received!.
                                </label>
                            </div>
                        ) : null}
                        <form>
                            <div className="input-block">
                                <label>Name</label>
                                <input
                                    type="text"
                                    className="text-field"
                                    onChange={e => setName(e.target.value)}
                                    maxLength="256"
                                    name="name"
                                    data-name="Name"
                                    placeholder="Your name"
                                    id="name"
                                    required=""
                                    autoComplete="off"
                                />
                            </div>
                            <div className="input-block">
                                <label>Email Address</label>
                                <input
                                    type="email"
                                    className="text-field w-input"
                                    onChange={e =>
                                        setEmailAddress(e.target.value)
                                    }
                                    maxLength="256"
                                    name="email-2"
                                    data-name="Email 2"
                                    placeholder="Your email"
                                    id="email-2"
                                    required=""
                                />
                            </div>
                            <div className="input-block">
                                <label>Message</label>
                                <textarea
                                    id="field"
                                    name="field"
                                    maxLength="5000"
                                    onChange={e => setMessage(e.target.value)}
                                    data-name="field"
                                    placeholder="Enter message here"
                                    className="text-field text-area w-input"
                                ></textarea>
                            </div>
                            {errorShow ? (
                                <div className="input-block error">
                                    <label>
                                        Oops! Something went wrong while
                                        submitting the form.
                                    </label>
                                </div>
                            ) : null}
                            <div className="text-center">
                                <input
                                    type="button"
                                    value="Submit"
                                    className="btn"
                                    onClick={handleOnSubmit}
                                />
                            </div>
                        </form>
                    </div>
                </div>
            </section>

            <footer>
                <div className="container">
                    <div className="grid">
                        <div className="left"></div>
                        <div className="center">
                            <a href="/contacts" className="footer-link">
                                Contacts
                            </a>
                        </div>
                        <div className="right">
                            <a
                                href="https://discord.gg/galaxypeeps"
                                target="_blank"
                                className="social-icon"
                                rel="noreferrer"
                            >
                                <img src={discord} className="" alt="discord" />
                            </a>
                            <a
                                href="https://twitter.com/GalaxyPeepsNFT"
                                target="_blank"
                                className="social-icon"
                                rel="noreferrer"
                            >
                                <img src={twitter} className="" alt="twitter" />
                            </a>
                            <a
                                href="https://www.youtube.com/channel/UCAE1CAbYyZ-lbED8oBA9y0Q"
                                target="_blank"
                                className="social-icon"
                                rel="noreferrer"
                            >
                                <img src={youtube} className="" alt="youtube" />
                            </a>
                        </div>
                    </div>
                    <div className="reserved">
                        <FooterCopyright />
                    </div>
                </div>
            </footer>
        </div>
    );
};
export default Contact;
