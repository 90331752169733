import { MerkleTree } from 'merkletreejs';
import keccak256 from 'keccak256';
import { ethers } from 'ethers';

import preSaleMintList from '../config/presale_list.json';
import ogSaleMintList from '../config/og_list.json';
import freeSaleMintList from '../config/freemint_list.json';

export enum MintPass {
    PreSale = '0',
    OgSale = '1',
    FreeSale = '2'
}

export interface MintMerkleData {
    address: string;
    preSale: boolean;
    ogSale: boolean;
    freeSale: boolean;
    freeSaleAmount: number;
}

let merkleTree: MerkleTree;

export function getFreeAmount(address: string): number {
    return (
        freeSaleMintList.find(
            a => a.address.toLowerCase() === address.toLowerCase()
        )?.amount ?? 0
    );
}

function getMerkleTree(): MerkleTree {
    if (!merkleTree) {
        const leavesPreSale = preSaleMintList.map(address =>
            keccak256(
                ethers.utils.solidityPack(
                    ['address', 'uint8'],
                    [address.toLowerCase(), MintPass.PreSale]
                )
            )
        );
        const leavesOgSale = ogSaleMintList.map(address =>
            keccak256(
                ethers.utils.solidityPack(
                    ['address', 'uint8'],
                    [address.toLowerCase(), MintPass.OgSale]
                )
            )
        );
        const leavesFreeSale = freeSaleMintList.map(entry =>
            keccak256(
                ethers.utils.solidityPack(
                    ['address', 'uint8', 'uint256'],
                    [
                        entry.address.toLowerCase(),
                        MintPass.FreeSale,
                        entry.amount.toString()
                    ]
                )
            )
        );
        merkleTree = new MerkleTree(
            [...leavesPreSale, ...leavesOgSale, ...leavesFreeSale],
            keccak256,
            { sortPairs: true }
        );
    }
    return merkleTree;
}

export function getProof(address: string, pass: MintPass): string[] | null {
    if (pass === MintPass.FreeSale) {
        const amount = getFreeAmount(address);
        if (amount === 0) return null;
        return getMerkleTree().getHexProof(
            keccak256(
                ethers.utils.solidityPack(
                    ['address', 'uint8', 'uint256'],
                    [address.toLowerCase(), pass, amount]
                )
            )
        );
    } else {
        return getMerkleTree().getHexProof(
            keccak256(
                ethers.utils.solidityPack(
                    ['address', 'uint8'],
                    [address.toLowerCase(), pass]
                )
            )
        );
    }
}

export function getMerkleData(address: string | null): MintMerkleData {
    if (!address)
        return {
            address: '',
            preSale: false,
            ogSale: false,
            freeSale: false,
            freeSaleAmount: 0
        };
    address = address.toLowerCase();

    const preSale =
        preSaleMintList.find(a => a.toLowerCase() === address) !== undefined;
    const ogSale =
        ogSaleMintList.find(a => a.toLowerCase() === address) !== undefined;
    const freeSaleAmount = getFreeAmount(address);
    const freeSale = freeSaleAmount > 0;
    return {
        address,
        preSale,
        ogSale,
        freeSale,
        freeSaleAmount
    };
}

export function getRoot() {
    return getMerkleTree().getHexRoot();
}
