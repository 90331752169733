import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import './App.scss';
import axios from 'axios';

axios.defaults.withCredentials = true;

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);
