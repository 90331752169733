import team01 from '../img/team/team01.png';
import team02 from '../img/team/team02.png';
import team03 from '../img/team/team03.png';
import team04 from '../img/team/team04.png';
import team05 from '../img/team/team05.png';
import team06 from '../img/team/team06.png';
import team07 from '../img/team/team07.png';
import team08 from '../img/team/team08.png';
import team09 from '../img/team/team09.png';
import team10 from '../img/team/team10.png';
import team11 from '../img/team/team11.png';
import team12 from '../img/team/team12.png';
import team13 from '../img/team/team13.png';
import team14 from '../img/team/team14.png';
import team15 from '../img/team/team15.png';
import team16 from '../img/team/team16.png';

const Team = () => {
    return (
        <section id="team" className="team-container">
            <div className="container">
                <h2 className="section-title mb-60">
                    Creative <span className="text-accent">Team</span>
                </h2>
                <div className="row">
                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://twitter.com/Skykaynft"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team01}
                                    className="team-item"
                                    alt="Jacky"
                                />
                            </a>
                            <h3>Jacky</h3>
                            <p>Artistic Director/Dev</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://twitter.com/AngelaMimiNFT"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team02}
                                    className="team-item"
                                    alt="Angela"
                                />
                            </a>
                            <h3>Angela</h3>
                            <p>Chief Artist/Creator</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://twitter.com/PopchaserCrypto"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team03}
                                    className="team-item"
                                    alt="Sam"
                                />
                            </a>
                            <h3>Sam</h3>
                            <p>Marketing Advisor/Writer</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://twitter.com/RhysHall107"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team04}
                                    className="team-item"
                                    alt="Reece"
                                />
                            </a>
                            <h3>Reece</h3>
                            <p>Strategic Advisor/Marketing Chief</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://www.linkedin.com/in/miltonsoong/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team05}
                                    className="team-item"
                                    alt="Milton"
                                />
                            </a>
                            <h3>Milton</h3>
                            <p>Business Advisor</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://twitter.com/web3elab"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team11}
                                    className="team-item"
                                    alt="Shan"
                                />
                            </a>
                            <h3>Shan</h3>
                            <p>Blockchain Developer</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://twitter.com/sanleo461"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team12}
                                    className="team-item"
                                    alt="SanLeo"
                                />
                            </a>
                            <h3>SanLeo</h3>
                            <p>Web3 Developer</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://twitter.com/kevgreat4_d"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team13}
                                    className="team-item"
                                    alt="Kevins"
                                />
                            </a>
                            <h3>Kevins</h3>
                            <p>Community Team/Admin</p>
                        </div>
                    </div>

                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://twitter.com/speedajaks"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team06}
                                    className="team-item"
                                    alt="Kennys"
                                />
                            </a>
                            <h3>Kennys</h3>
                            <p>Community Team</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://twitter.com/melllviiin/"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team07}
                                    className="team-item"
                                    alt="Kennys"
                                />
                            </a>
                            <h3>M3lvin</h3>
                            <p>Community Team</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://twitter.com/KubbyTrustard"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team08}
                                    className="team-item"
                                    alt="Kennys"
                                />
                            </a>
                            <h3>Kubby Trustard</h3>
                            <p>Community Team</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://twitter.com/Ch0k07"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team14}
                                    className="team-item"
                                    alt="Kennys"
                                />
                            </a>
                            <h3>Choko</h3>
                            <p>Community Team</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://twitter.com/dege_9662"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team10}
                                    className="team-item"
                                    alt="Kennys"
                                />
                            </a>
                            <h3>Dege</h3>
                            <p>Collab Manager</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://twitter.com/Badboy_0206"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team09}
                                    className="team-item"
                                    alt="Kennys"
                                />
                            </a>
                            <h3>Big Scott</h3>
                            <p>Collab Manager</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://twitter.com/No_nyee"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team16}
                                    className="team-item"
                                    alt="GooNo"
                                />
                            </a>
                            <h3>GooNo</h3>
                            <p>Collab Manager</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://twitter.com/3amdexter"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team06}
                                    className="team-item"
                                    alt="0xDTS"
                                />
                            </a>
                            <h3>0xDTS</h3>
                            <p>Collab Manager</p>
                        </div>
                    </div>
                    <div className="col-md-4 col-sm-6">
                        <div className="inner">
                            <a
                                href="https://twitter.com/AndrejNFT_"
                                target="_blank"
                                rel="noreferrer"
                            >
                                <img
                                    src={team15}
                                    className="team-item"
                                    alt="Kennys"
                                />
                            </a>
                            <h3>Andrej</h3>
                            <p>Collab Manager</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Team;
