import story from '../img/story.png';

const Faq = () => {
    return (
        <section id="story" className="story-container">
            <div className="container">
                <div className="row">
                    <div className="col-sm-3 left">
                        <h2 className="section-title mb-60">
                            Background{' '}
                            <span className="text-accent">Story</span>
                        </h2>
                        <div className="img-container">
                            <img
                                src={story}
                                className="scroll-slider-item"
                                alt="logo"
                            />
                        </div>
                    </div>
                    <div className="col-sm-9 right">
                        <div
                            className="panel-group"
                            id="storyCollapse"
                            role="tablist"
                            aria-multiselectable="true"
                        >
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading"
                                    role="tab"
                                    id="heading1"
                                >
                                    <h4 className="panel-title">
                                        <a
                                            role="button"
                                            data-toggle="collapse"
                                            data-parent="#storyCollapse"
                                            href="#storycollapse1"
                                            aria-expanded="true"
                                            aria-controls="storycollapse1"
                                            className="collapsed"
                                        >
                                            Prelude
                                            <span
                                                className="glyphicon glyphicon-menu-down"
                                                aria-hidden="true"
                                            ></span>
                                        </a>
                                    </h4>
                                </div>
                                <div
                                    id="storycollapse1"
                                    className="panel-collapse collapse"
                                    role="tabpanel"
                                    aria-labelledby="heading1"
                                >
                                    <div className="panel-body">
                                        <p>
                                            In a galaxy far away, so very far
                                            away, so unbelievably far away, like
                                            your head will explode just thinking
                                            how far away it is, there are . . .
                                            GALAXY PEEPS!
                                        </p>
                                        <iframe
                                            className="embedly-embed"
                                            src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FlKOumqKEi_I%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DlKOumqKEi_I&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FlKOumqKEi_I%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube"
                                            scrolling="no"
                                            allowFullScreen=""
                                            title="Galaxy Peeps ~ NEW🔥HOT🔥NFT project you have been waiting for a very very very long time!!"
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading"
                                    role="tab"
                                    id="heading2"
                                >
                                    <h4 className="panel-title">
                                        <a
                                            role="button"
                                            data-toggle="collapse"
                                            data-parent="#storyCollapse"
                                            href="#storycollapse2"
                                            aria-expanded="true"
                                            aria-controls="storycollapse2"
                                            className="collapsed"
                                        >
                                            Episode I: ESCAPE TO EARTH
                                            <span
                                                className="glyphicon glyphicon-menu-down"
                                                aria-hidden="true"
                                            ></span>
                                        </a>
                                    </h4>
                                </div>
                                <div
                                    id="storycollapse2"
                                    className="panel-collapse collapse"
                                    role="tabpanel"
                                    aria-labelledby="heading2"
                                >
                                    <div className="panel-body">
                                        <p>
                                            The evil EMPEROR SAAD IV invaded the
                                            Magic Kingdom of ORION, imprisoned
                                            the old King and Queen, and wanted
                                            to marry the beautiful PRINCESS TIA
                                            to control the kingdom. With the
                                            help of her trusted and loyal
                                            friends, including CAPTAIN TEDDY,
                                            SIR ONION HEAD, and MASTER
                                            STARKICKER, PRINCESS TIA escaped and
                                            bought a used merchant ship to fly
                                            to a distant planet called EARTH,
                                            the capital planet of TERRAN
                                            FEDERATION. She heard many enticing
                                            stories about EARTH that stands for
                                            truth and justice, and hopes to get
                                            help to take back her kingdom! So
                                            begins her dangerous yet exciting
                                            adventures to the blue planet!
                                        </p>
                                        <iframe
                                            className="embedly-embed"
                                            src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FMt2JLn4ZJE0%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DMt2JLn4ZJE0&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FMt2JLn4ZJE0%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube"
                                            scrolling="no"
                                            allowFullScreen=""
                                            title="💫Galaxy Peeps✨Episode I: ESCAPE TO EARTH!🌏New Top Web3 NFT Project!"
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading"
                                    role="tab"
                                    id="heading3"
                                >
                                    <h4 className="panel-title">
                                        <a
                                            role="button"
                                            data-toggle="collapse"
                                            data-parent="#storyCollapse"
                                            href="#storycollapse3"
                                            aria-expanded="true"
                                            aria-controls="storycollapse3"
                                            className="collapsed"
                                        >
                                            Episode II: VERY VERY FAR FROM HOME
                                            <span
                                                className="glyphicon glyphicon-menu-down"
                                                aria-hidden="true"
                                            ></span>
                                        </a>
                                    </h4>
                                </div>
                                <div
                                    id="storycollapse3"
                                    className="panel-collapse collapse"
                                    role="tabpanel"
                                    aria-labelledby="heading3"
                                >
                                    <div className="panel-body">
                                        <p>
                                            Emperor Saad was furious after
                                            finding out Princess Tia had escaped
                                            and told his generals to send
                                            special guards and mercenaries to
                                            capture her back...General Doofus
                                            proudly proclaimed to Emperor: “Not
                                            to worry, my Majesty, I will surely
                                            bring back Princess Tia, dead or
                                            alive!” Upon hearing this, Emperor
                                            slapped General Doofus across the
                                            head and yelled at him: “Idiot! How
                                            can I marry the princess if she’s
                                            dead? Bring her back alive and not a
                                            scratch on her body, you hear me?”
                                            General Doofus sheepishly replied,
                                            “yes, Majesty,” and kicked his
                                            soldiers on the butt on his way out
                                            of the throne room….
                                        </p>
                                        <p>
                                            While on earth, Princess Tia and her
                                            ship crash landed in a small town
                                            called Roswell, in a country called
                                            America! She and her friends
                                            disguised themselves as locals but
                                            found that people in this town were
                                            very welcoming to aliens! There were
                                            UFO cafes and shops everywhere, and
                                            people dressed up as aliens, so they
                                            felt very much at home here! The
                                            locals showed her Earth delicacies
                                            such as pizza, ice cream, and
                                            tacos!! She loves this new place and
                                            has already made some good friends
                                            with Earthlings! However, the danger
                                            is coming her way from across the
                                            galaxy…..
                                        </p>
                                        <iframe
                                            className="embedly-embed"
                                            src="//cdn.embedly.com/widgets/media.html?src=https%3A%2F%2Fwww.youtube.com%2Fembed%2FB_51x71EEZs%3Ffeature%3Doembed&amp;display_name=YouTube&amp;url=https%3A%2F%2Fwww.youtube.com%2Fwatch%3Fv%3DB_51x71EEZs&amp;image=https%3A%2F%2Fi.ytimg.com%2Fvi%2FB_51x71EEZs%2Fhqdefault.jpg&amp;key=96f1f04c5f4143bcb0f2e68c87d65feb&amp;type=text%2Fhtml&amp;schema=youtube"
                                            scrolling="no"
                                            allowFullScreen=""
                                            title="💫Galaxy Peeps✨Episode II: VERY VERY FAR FROM HOME🌏Top Web3 Story-driven humor-based NFT collection"
                                        ></iframe>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading"
                                    role="tab"
                                    id="heading3"
                                >
                                    <h4 className="panel-title">
                                        <a
                                            role="button"
                                            data-toggle="collapse"
                                            data-parent="#storyCollapse"
                                            href="#storycollapse4"
                                            aria-expanded="true"
                                            aria-controls="storycollapse4"
                                            className="collapsed"
                                        >
                                            Episode III
                                            <span
                                                className="glyphicon glyphicon-menu-down"
                                                aria-hidden="true"
                                            ></span>
                                        </a>
                                    </h4>
                                </div>
                                <div
                                    id="storycollapse4"
                                    className="panel-collapse collapse"
                                    role="tabpanel"
                                    aria-labelledby="heading3"
                                >
                                    <div className="panel-body">
                                        <p>Coming soon!</p>
                                    </div>
                                </div>
                            </div>
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading"
                                    role="tab"
                                    id="heading5"
                                >
                                    <h4 className="panel-title">
                                        <a
                                            role="button"
                                            data-toggle="collapse"
                                            data-parent="#storyCollapse"
                                            href="#storycollapse5"
                                            aria-expanded="true"
                                            aria-controls="storycollapse5"
                                            className="collapsed"
                                        >
                                            Episode IV
                                            <span
                                                className="glyphicon glyphicon-menu-down"
                                                aria-hidden="true"
                                            ></span>
                                        </a>
                                    </h4>
                                </div>
                                <div
                                    id="storycollapse5"
                                    className="panel-collapse collapse"
                                    role="tabpanel"
                                    aria-labelledby="heading5"
                                >
                                    <div className="panel-body">
                                        <p>Coming soon!</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};
export default Faq;
